import { objectKeys } from 'lib/object/objectUtils'
import { useResponsive } from './useResponsive'

/**
 * Maps screen sizes to arbitrary values according to the options config passed in.
 */
function useResponsiveSelector<ValueType>(config: Record<App.ScreenSize, ValueType>): ValueType
function useResponsiveSelector<ValueType>(config: Partial<Record<App.ScreenSize, ValueType>>): ValueType | undefined
function useResponsiveSelector<ValueType>(config: Partial<Record<App.ScreenSize, ValueType>>): ValueType | undefined {
  const [breakpointMatches] = useResponsive()
  const matchedSize = objectKeys(config).find(size => breakpointMatches[size])

  return matchedSize ? config[matchedSize] : undefined
}

export default useResponsiveSelector
